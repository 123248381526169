// @import url('https://fonts.googleapis.com/css2?family=Graphik:wght@200;300;400;500;600;700;800;900&display=swap');
@import "./fonts.scss";

body {
 margin: 0;
 padding: 0;
 font-family: 'Graphik', sans-serif;
}

* {
 margin: 0;
 padding: 0;
}

ul {
 list-style: none;
 margin: 0;
 padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
 margin: 0;
 padding: 0;
}

p {
 margin: 0;
 padding: 0;
}

a:hover {
 text-decoration: none;
}

/* faq css open */
.faq-wrap {
    font-family: 'Graphik';   
    padding: 60px 0 100px 0;
    @media (max-width:767px) {
      padding: 20px 0 40px 0;
    }
  .hero-wrap {
    margin-bottom: 90px;
  .banner-txt {
      width: 50%;
      @media (max-width:767px) {
        width: 100%;
      }
      h2 {
        font-family: 'Graphik';
        font-size: 30px;
        line-height: 46px;
        color: #000000;
        width: 400px;
        max-width: 100%;
          @media (max-width:767px) {
            font-size: 28px;
            line-height: 36px;
          }
      }

      p {
        width: 320px;
        max-width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }
    }

    .banner-img {
      width: 50%;
      position: relative;
      padding-top: 30%;
      @media (max-width:767px) {
        padding-top: 58%;
        width: 100%;
        margin-top: 20px;
      }

      img, video, iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
      .playbtn {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        border: 0;
        background: none;
      }
    }
  }
  .faq-card {
    h6 {
      font-family: 'Graphik';
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
    h1 {
      font-family: 'Graphik';
      font-size: 34px;
      line-height: 46px;
      text-align: center;
      color: #000000;
      @media (max-width:767px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .accordian-section {
      transition: all .3s ease-in-out;
      width: 100%;
      max-width: 100%;
    
      .accordion {
        border: 0;
        .accordion__item {
          &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
      .accordion__panel {
        transition: all .3s ease-in-out;
        padding: 0px 0px 20px 0px;
        p {          
          font-family: 'Graphik';
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }
      .accordion__button {
        background-color: #ffffff;
        cursor: pointer;
        padding: 24px 34px 24px 0;
        width: 100%;
        text-align: left;
        border: none;
        font-family: 'Graphik';
        font-size: 16px;
        line-height: 24px;              
        color: #000000;
        position: relative;
        &::before {
          display: inline-block;
          content: '';
          height: 24px;
          width: 24px;
          background-size: 23px !important;
          // background: url('../images/ic-accordian-minus.svg') no-repeat;
          margin-right: 0;
          transform: rotate(-180deg);
          border: 0;
          position: absolute;
          top: 26px;
          right: 0;
          left: auto;
          transition: all .3s ease-in-out;
        }
        &[aria-expanded="false"] {
          &::before {
            content: '';
            background: url('../images/ic-accordian-plus.svg') no-repeat;
            transform: rotate(0deg);
            transition: all .3s ease-in-out;
          }
        }
        &[aria-expanded="true"] {
          &::before {
            content: '';
            background: url('../images/ic-accordian-minus.svg') no-repeat;
            transition: all .3s ease-in-out;
          }
        }
      }
    }
  }
}
/* faq css close */

/* contact css open */
.contact-wrap {
  font-family: 'Graphik';
  padding: 60px 0 0 0;
  h2 {   
      font-family: 'Graphik';
      font-size: 34px;
      line-height: 46px;
      text-align: center;
      color: #000000;
      @media (max-width:767px) {
        font-size: 28px;
        line-height: 40px;
      }
  }
  p {
      width: 420px;
      max-width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin: 0 auto 60px;
  }
  .contact-form {
    background: #F2F7FF;
    border-radius: 30px;
    padding: 80px 40px 40px 40px;
    margin: 0 0 60px 0;
    text-align: center;
    @media (max-width:767px) {
      padding: 40px 20px 40px 20px;
    }
    .form-listing {
      list-style: none;
      margin: 0;
      padding: 0;
      .flex-inp {
        display: flex;
        align-items: center;
      }
      li {
        width: 647px;
        max-width: 100%;
        background: #FFFFFF;
        border-radius: 7px;
        font-family: 'Graphik';
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: #919191;
        box-shadow: 8px 8px 20px rgba(0, 15, 255, 0.04);
        margin: 0 auto 30px;
        position: relative;
        p {
          width: 100% !important;
          margin: 0 !important;
          position: absolute;
          left: 20px;
          text-align: left !important;
          color: red;
          letter-spacing: normal !important;
          font-size: 14px !important;
        }
        .country-code {
          width: 50px;
          height: 50px;
          border: 0;
          padding: 0 14px;
          border-radius: 7px;
          display: flex;
          align-items: center;
          color: #005FDD;
        }
        .inp-style::-webkit-input-placeholder,
        .inp-style::-webkit-input-placeholder {
            color: #919191;
            font-family: 'Graphik';
            font-weight: 500;
          }
        
        .inp-style:-ms-input-placeholder,
        .inp-style:-ms-input-placeholder {
          color: #919191;
          font-family: 'Graphik';
          font-weight: 500;
        }
        
        .inp-style::placeholder,
        .inp-style::placeholder {
          color: #919191;
          font-family: 'Graphik';
          font-weight: 500;
        }

        .inp-style {
          width: 100%;
          height: 50px;
          border: 0;
          padding: 0 18px;
          border-radius: 7px;
          font-family: 'Graphik';
          font-weight: 500;
          font-size: 16px;
          color: #919191;
          &:focus {
            outline: none;
          }
        }
        .textarea-style {
          width: 100%;
          height: 150px;
          border: 0;
          padding: 18px;
          resize: none;
          border-radius: 7px;
          font-family: 'Graphik';
          font-weight: 500;
          font-size: 16px;
          color: #919191;
          &:focus {
            outline: none;
          }
        }
        &:last-child {
          box-shadow: none;
          .btn-filled {
            min-width: 100%;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
  .map-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    .map-sec {
      width: 60%;
      @media (max-width:767px) {
        width: 100%;
      }
      iframe {
        width: 100%;
        height: 360px;
        border: 0;
        border-radius: 30px;
        border: 1px solid #ccc;
      }
    }
    .map-txt {
      width: 40%;
      padding-left: 40px;
      @media (max-width:767px) {
        width: 100%;
        padding: 20px 0 0 0;
      }
      h4 {        
        font-family: 'Graphik';
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;        
        color: #000000;
        margin: 0 0 20px 0;
        span {
          font-weight: 600;
          min-width: 70px;
          display: inline-block;
        }
        a {
          color: #000000;
          text-decoration: none;
          &:hover {
            color: #000000;
            text-decoration: none;
          }
        }
      }
    }
  }
}
/* contact css open */

/* home css open */
.hero-section {
 margin-bottom: 70px;
 position: relative;
 .hero-img-wrap {
  position: relative;
  padding-top: 28%;
    @media (max-width:767px) {
      padding-top: 56%;
    }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
 img {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   object-fit: cover;
   width: 100%;
   height: 100%;
  }
 }
 .hero-text {
  width: 860px;
  max-width: 94%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  h3 {   
    font-family: 'Graphik';
    font-size: 42px;
    line-height: 58px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.6);
    margin: 0;
    padding: 0;
    @media (max-width:980px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media (max-width:767px) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  .btn-filled {
    margin-top: 20px;
    font-family: 'Graphik';
    @media (max-width:767px) {
      min-width: 160px;
    }
  }
 }
}
.service-section {
h1 {
  width: 970px;
  max-width: 100%;
  font-family: 'Graphik';
  font-size: 42px;
  line-height: 54px;
  text-align: center;
  color: #282828;
  margin: 100px auto 40px;
  @media (max-width:767px) {
    font-size: 28px;
    line-height: 40px;
  }
 }
  .service-listing {
   list-style: none;
   margin: 0;
   padding: 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   li {
    width: 350px;
    max-width: 27%;
    @media (max-width:767px) {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }
    img {
      width: auto;
      margin-bottom: 20px;
      height: 100px;
     }
    p {
     font-weight: 400;
     font-size: 16px;
     line-height: 24px;
     color: #282828;
     margin: 0;
     padding: 0;
    }
   }
  }
    .btn-bordered, .btn-filled {
      @media (max-width:767px) {
        width: 100%;
        margin: 7px 0;
      }
    }
}

.btn-bordered {
 border: 2px solid #2C2C2C;
 border-radius: 6px;
 background: #fff;
 font-family: 'Graphik';
 font-size: 16px;
 line-height: 28px;
 text-align: center;
 color: #282828!important;
 padding: 10px 10px;
 margin: 5px;
 min-width: 200px;
 display: inline-block;
 text-decoration: none!important;
 &:focus {
   text-decoration: none !important;
   color: #282828 !important;
  }
}
.btn-filled {
 border: 2px solid #005FDD;
 border-radius: 6px;
 background: #005FDD;
 font-family: 'Graphik';
 font-size: 16px;
 line-height: 28px;
 text-align: center;
 color: #fff !important;
 padding: 10px 10px;
 margin: 5px;
 min-width: 200px;
 display: inline-block;
 text-decoration: none !important;
 &:focus {
  text-decoration: none !important;
  color: #fff !important;
 }
}



.cities-section {
 padding: 80px 0;
 h4 {
   font-family: 'Graphik';
   font-size: 24px;
   line-height: 32px;
   color: #000000;
   margin: 0 0 14px 0;
  }
    .cities-listing {
     display: flex;
     flex-wrap: wrap;
     margin: 0 -1.5%;
      @media (max-width:767px) {
        margin: 0;
      }
     li {
       width: 30.333%;
       margin: 1.5%;
       position: relative;
       @media (max-width:767px) {
        width: 100%;
        margin: 1.5% 0;
       }
        .overlay-img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.3);
        }
       .img-box {
         position: relative;
         padding-top: 68%;
         width: 100%;
         img {
           width: 100%;
           height: 100%;
           object-fit: cover;
           position: absolute;
           top: 0;
           right: 0;
           bottom: 0;
           left: 0;
           border-radius: 10px;
          }
        }
         h4 {
          position: absolute;
          left: 24px;
          bottom: 20px;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: #FFFFFF;
          margin: 0;
          font-family: 'Graphik';
         }
      }
    }
}

.moke-section {
 padding: 0 0 100px 0;
 .moke-bg {
   background: #F2F7FF;
   border-radius: 30px;
   padding: 30px 0;
   .make-flex {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
    }
    .img-box {
     width: 50%;
     @media (max-width:980px) {
       width: 100%;
       text-align: center;
     }
     img {
       width: 100%;
        @media (max-width:980px) {
          width: 70%;
        }
      }
    }
    .img-txt {
     width: 50%;
     padding: 0 40px 0 0;
      @media (max-width:980px) {
        width: 100%;
        padding: 40px;
      }
      @media (max-width:767px) {
        padding: 40px 20px;
      }
     h1 {
       font-family: 'Graphik';
       font-size: 30px;
       line-height: 50px;
       color: #090909;
       margin: 0 0 10px 0;
        @media (max-width:767px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      p {
       font-family: 'Graphik';
       font-weight: 500;
       font-size: 16px;
       line-height: 26px;
       color: #090909;
      }
    }
  }
}


.features-section {
 padding: 0 0 60px 0;
font-family: 'Graphik';
 h1 {
   font-weight: 700;
   font-size: 40px;
   line-height: 50px;
   color: #090909;
   margin: 0 0 10px 0;
   text-align: center;
  font-family: 'Graphik';
  @media (max-width:767px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
  p {
   font-weight: 500;
   font-size: 16px;
   line-height: 26px;
   color: #090909;
   text-align: center;
   width: 400px;
   max-width: 100%;
   margin: 0 auto 60px;
   display: block;
  }
 .features-listing {
  display: flex;
  flex-wrap: wrap;

  li {
   width: 30.333%;
   margin: 1.5%;
   border: 2px solid #C4C4C4;
   border-radius: 35px;
   padding: 30px 30px 10px 30px;
   @media (max-width:767px) {
    width: 100%;
    margin: 2% 0;
   }

   img {
    height: 72px;
    margin-bottom: 50px;
   }

   h4 {
    font-family: 'Graphik';
    font-size: 20px;
    line-height: 32px;
    color: #000000;
   }
  }
 }
}

.testimonials-section {
 padding-bottom: 60px;
 .testimonials-bg {
   width: 100%;
   background: #040404;
   border-radius: 30px;
   padding: 100px 50px 50px 50px;
   @media (max-width:767px) {
      padding: 50px 30px 50px 30px;
   }
   p {
     font-family: 'Graphik';
     font-weight: 400;
     font-size: 16px;
     line-height: 30px;
     color: #FFFFFF;
     width: 80%;
     margin: 0 auto;
    @media (max-width:767px) {
      width: 100%;
    }
   }
   h4 {
     font-weight: 600;
     font-family: 'Graphik';
     font-size: 20px;
     line-height: 32px;
     text-align: right;
     color: #FFFFFF;
     width: 80%;
     margin: 50px auto 50px;
     padding: 0;
   }
   .slick-dots {
    li {
     button {
      &::before {
       font-size: 14px;
       line-height: 20px;
       position: absolute;
       top: 0;
       left: 0;
       width: 20px;
       height: 20px;
       content: '•';
       text-align: center;
       opacity: .35;
       color: #fff;
      }
     }
    }
    li.slick-active {
     button {
       &::before {
        color: #005FDD;
        opacity: 1;
       }
      }
    }
   }
   .slick-prev {
    &::before {
     content: "";
     font-size: 0;
    }
    &::after {
     content: "" !important;
     background: url('../images/ic-arrow-prev.svg') no-repeat;
     width: 30px !important;
     height: 30px !important;
     background-size: 24px;
     background-position: center center;
     position: absolute;
     top: 0;
     z-index: 9;
    }
   }
      .slick-next {
       right: 0;
       &::before {
        content: "";
        font-size: 0;
       }
   
       &::after {
        content: "" !important;
        background: url('../images/ic-arrow-next.svg') no-repeat;
        width: 30px !important;
        height: 30px !important;
        background-size: 24px;
        background-position: center center;
        position: absolute;
        top: 0;
        z-index: 9;
       }
      }
 }
}


.pictures-section {
 padding-bottom: 100px;
 .pictures-bg {
  .imgwrap {
   padding-top: 140%;
   position: relative;
   img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 94%;
    height: 100%;
    margin: 0 auto;
   }
  }
    .slick-dots {
     bottom: -50px;
     li {
      button {
       &::before {
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: .35;
        color: #000;
       }
      }
     }
  
     li.slick-active {
      button {
       &::before {
        color: #005FDD;
        opacity: 1;
       }
      }
     }
    }
  
    .slick-prev {
     z-index: 9;
     left: -55px;
     &::before {
      content: "";
      font-size: 0;
     }
  
     &::after {
      content: "" !important;
      background-image: url('../images/version2/left-arrow.svg');
      width: 50px !important;
      height: 50px !important;
      background-size: 24px!important;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
      z-index: 9;
     }
    }
  
    .slick-next {
      right: -15px !important;
     &::before {
      content: "";
      font-size: 0;
     }
  
     &::after {
      content: "" !important;
      background-image: url('../images/version2/right-arrow.svg');
      background-repeat: no-repeat;
      width: 50px !important;
      height: 50px !important;
      background-size: 24px!important;
      background-position: center center;
      position: absolute;
      top: 0;
      z-index: 9;
     }
    }
 }
}
/* home css close */

/* history css open */
.mock-what {
  font-family: 'Graphik', sans-serif;
  h2 {
      font-family: 'Graphik';
      font-size: 32px;
      line-height: 46px;
      color: #090909;
      @media (max-width:767px) {
        font-size: 28px;
        line-height: 40px;
      }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #090909;
  }
  .what-listing {
    list-style: none;
    margin: 20px 0 100px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    li {
      &:first-child {
        width: 50%;
        padding-top: 38%;
        height: 100%;
        position: relative;
        display: flex;
        &::before {
          content: "";
          width: 100%;
          height: 75%;
          background-color: #005FDD;
          position: absolute;
          right: 0;
          top: 0;
          }
        img {
          width: 90%;
          height: 85%;
          object-fit: cover;
          position: absolute;
          top: 15%;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
      &:nth-child(2) {
        position: absolute;
        left: 52%;
        top: 0;
        width: 48%;
        padding-top: 37%;
        img {
          width: 100%;
          height: 48%;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
      &:nth-child(3) {
        position: absolute;
        left: 52%;
        top: 53%;
        width: 48%;
        padding-top: 37%;
        img {
          width: 100%;
          height: 48%;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          }
      }
    }
  }
}
.then-now-listing {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Graphik', sans-serif;
  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .left-sec {
      width: 50%;
      padding-top: 36%;
      position: relative;
      @media (max-width:767px) {
        width: 100%;
        padding-top: 68%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .right-sec {
      width: 50%;
      padding: 0 40px;
      box-sizing: border-box;
      @media (max-width:767px) {
        width: 100%;
        padding: 0;
      }
      h4 {        
          font-family: 'Graphik';
          font-size: 32px;
          line-height: 46px;        
          color: #090909;
          margin: 0 0 5px 0;
          @media (max-width:767px) {
            margin: 10px 0 5px 0;
            font-size: 28px;
            line-height: 40px;
          }
      }
      p {        
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #090909;
      }
    }
  }
}
.success-msg {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #2c8b14;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* history css close */

// header css open
.activetab {
  opacity: 1 !important;
}
.brandlogo {
  // height: 52px;
  height: 84px;
  @media (max-width: 767px) {
    height: 64px;
  }
}
.ic-menu {
  display: none;
  background: none;
  border: 0;
  @media (max-width: 767px) {
    display: inline-block;
  }
}
.openmenu.header-links {
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transform: translateX(0%) !important;
  -webkit-transform: translateX(0%) !important;
  -moz-transform: translateX(0%) !important;
}
.header-flex {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
  @media (max-width: 767px) {
    justify-content: space-between;
  }
  .header-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 0 60px;
    padding: 0;
    @media (max-width: 767px) {
      display: unset;
      position: fixed;
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      width: 100%;
      // height: calc(100vh - 68px);
      height: calc(100vh - 84px);
      background: #fff;
      z-index: 99;
      bottom: 0;
      left: 0;
      right: 0;
      // top: 68px;
      top: 84px;
      margin: 0;
      padding-top: 30px;
      transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
    }
    li {
      @media (max-width: 767px) {
        width: 100%;
        display: block;
      }
      a {       
          font-family: 'Graphik';
          font-size: 14px;
          line-height: 19px;        
          color: #000000;
          font-weight: 600 !important;
          opacity: 0.6;
          padding: 0 24px;
          text-decoration: none;
          letter-spacing: 0.5px;
          @media (max-width: 767px) {
            padding: 20px 24px;
            text-decoration: none;
            width: 100%;
            display: block;
          }
      }
    }
  }
}
// header css close

// footer css open
.footer {
 background-color: #000;
 padding: 40px 0 20px 0;
 font-family: 'Graphik', sans-serif;
.ml-auto {
  @media (max-width:767px) {
    margin-left: 15px !important;
  }
}
 h4 {  
   font-family: 'Graphik';
   font-size: 18px;
   line-height: 56px;  
   color: #FFFFFF;
   position: relative;
   margin-bottom: 20px;
   &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #005FDD;
    height: 4px;
    width: 60px;
   }
 }
 .socialmedia {
  display: flex;
  li {
   margin-right: 10px;
   img {
     width: 30px;
    }
  }
 }
 a {
  font-family: 'Graphik';
   font-size: 14px;
   line-height: 28px; 
   color: #919191;
   display: block;
   &:hover {
    color: #919191;
    text-decoration: none;
   }
 }
 p {
  font-weight: 400;
   font-size: 14px;
   line-height: 28px;
   color: #919191;
   display: block;
   margin: 70px 0 0 0;
 }
}
.contact-underline {
  text-decoration: underline;
}
// footer css close

@media (max-width:980px) {
  .hide-scroll {
      max-height: 100vh;
      overflow-y: hidden !important;
    }
}

.pdflink {
  color: #0f7ff7;
  text-decoration: underline;
  &:hover {
    color: #0f7ff7;
  }
}

.faq-body .faq-row {
  @media (max-width:980px) {
    padding: 10px 15px !important;
  }
}

