@import url("./utils/loading/loading.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

}
.success-wrapper {
  background: #d6eafb;
  padding: 15px;
  box-shadow: rgb(149 157 165 / 55%) 0px 8px 24px;
  text-align: center;
}
.sucess-main-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messege {
  color: red;
  font-size: 19px;
  font-weight: 500;
  margin: 0;
}
.mail-succes-msg {
  margin: 10px 0;
}
.success-title {
  margin: 10px 0;
}
.success-icon {
  max-width: 65px;
  height: 65px;
  width: 100%;
  margin: 15px 0 0;
}
.failed-msg {
  color: red;
  margin: 10px 0;
}
.error-alert {
  color: red;
  font-size: 19px;
  font-weight: 500;
  margin: 10px 0;
}
.checkout-btn {
  background: black;
  color: white;
  padding: 9px 15px;
  border: 1px solid black;
  border-radius: 2px;
  margin: 0 0 10px 0;
}
.chatbot-renter-glass {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
}
.upload-img-wrapper {
  margin-bottom: 40px;
}
.upload-img-text {
  margin: 1rem 0;
  font-size: 13px;
  color: #323232;
  font-weight: 600;
}
.error_alert_msg {
  color: red;
  margin: 10px auto;
  max-width: 600px;
  padding: 0 10px;
}
.step4-next--btn {
  margin: 0 25px;
  max-width: 600px;
  margin: 0 auto;
}
.chatbox-renter {
  padding: 0 10px;
  max-width: 600px;
  margin: 0 auto;
}
.next__btn {
  padding: 7px 20px !important;
  background-color: #0a2740;
  border-radius: 6px !important;
  color: #fff;
  width: 100%;
  min-height: 50px;
  margin: 10px 0 10px 0;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
}
.upload-image-center {
  justify-content: center;
  position: relative;
  margin: 0 auto!important;
  width: 100%;
  max-width: 150px;
}
.img-control {
  object-fit: contain;
  max-height: 100%;
  width: 100%;

}
.image-cross {
  position: absolute !important;
  right: 3px !important;
  margin: 0 !important;
  top: 3px;
  background: #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-cross span {
  position: absolute;
  top: -4px;
}
.signature-box {
  border: 1px solid #c9c9c9;
  width: 230px;
  height: 110px;
}
.signature-wrapper {
  display: flex;
  align-items: end;
  flex-direction: column;
}
/* / ........................version2 --css........................... / */

:root {
    --blueBtn:#0A66E0;
    --blueBtn-spacing: 14px 25px;
    --blueBtn-color: #fff;

    --common-font: Graphik;
    --h4-font-size: 36px;
    --h4-font-weight: 700;
    --h4-margin: 0 0 25px 0;

    --p-font-size: 20px;
    --p-font-weight: 400;
    --text-color: #000;

    --sunshine-font-size: 36px;
    --sunshine-font-color: #fff;
    --sunshine-font-weight: 700;
    --sunshine-font-para: 16px;
    --sunshine-margin: 0 0 7px 0;
    --sunshine-para-margin: 0 0 27px 0;

     --h6-font-size:16px;
     --h6-font-weight:400;
  
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.sunshine-ride-wrapper {
  position: relative;
}
.sunshine-ride-wrapper .sunshine-img-wrap {
    position: relative;
    padding-top: 50%;
    width: 100%;
    margin-bottom: 138px;
}

.sunshine-ride-wrapper .sunshine-img-wrap img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
}

.sunshine-ride-wrapper .sunshine-text {
    width: 775px;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.sunshine-ride-wrapper .sunshine-text h3 {
    font-family: var(--common-font);
    font-weight: var(--sunshine-font-weight);
    font-size: var(--sunshine-font-size);
    color: var(--sunshine-font-color);
    margin: var(--sunshine-margin);
}

.sunshine-ride-wrapper .sunshine-text p {
    font-family: var(--common-font);
    font-weight: var(--sunshine-font-weight);
    font-size: var(--sunshine-font-para);
    color: var(--sunshine-font-color);
    margin: var(--sunshine-para-margin);
}

.sunshine-ride-wrapper .book-now, .book-now-2{
    background: var(--blueBtn);
    border: 1px solid var(--blueBtn);
    border-radius: 5px;
    font-family: var(--common-font);
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    padding: var(--blueBtn-spacing);
    color: var(--blueBtn-color);
    text-transform: uppercase;
}
.btn--margin-bottom {
  text-align: center;
  margin-bottom: 70px;
}
.btn--margin-bottom button {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  padding: var(--blueBtn-spacing);
  color: var(--blueBtn-color);
  text-transform: uppercase;
  background: var(--blueBtn);
  border: 1px solid var(--blueBtn);
  border-radius: 5px;
  font-family: var(--common-font);
}
.moke-section .moke-image {
    width: 100%;
    height: 100%;
}

.moke-section .moke-image img {
    width: 100%;
    height: 100%;
}

.moke-section .moke-text h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: var(--h4-margin);
}

.moke-section .moke-text p {
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    line-height: 40px;
}

.moke-section .moke-text .learn-more {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    padding: var(--blueBtn-spacing);
    color: var(--blueBtn-color);
    text-transform: uppercase;
    background: var(--blueBtn);
    border: 1px solid var(--blueBtn);
    border-radius: 5px;
    font-family: var(--common-font);
}

.moke-section .moke-text {
    padding-left: 60px;
}

.moke-section .moke-wrapper {
    align-items: center;
}

.moke-section {
    margin-bottom: 138px;
    padding: 0 !important;
}

.wemoke-exper-wrap-v2 .Exper-text h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: var(--h4-margin);
}

.wemoke-exper-wrap-v2 .Exper-text p {
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    line-height: 40px;
}

.wemoke-exper-wrap-v2 .Exper-text {
    padding: 0 60px;
}

.wemoke-exper-wrap-v2 img {
    width: 100%;
    height: 100%;
}

.wemoke-exper-wrap-v2 {
    margin-bottom: 70px;
}

.moke-rolls .moke-roll-text h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: var(--h4-margin);
}

.moke-rolls .moke-roll-text p {
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    line-height: 40px;
}

.moke-rolls .moke-roll-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap-reverse;
}

.moke-rolls .moke-roll-wrap .moke-roll-image {
    position: relative;
    padding-top: 50%;
    width: 45%;
}

.moke-rolls .moke-roll-wrap img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
}

.moke-rolls .moke-roll-text {
    padding-left: 85px;
    max-width: 475px;
    width: 55%;
} 
.moke-rolls {
    margin-bottom: 150px;
}
.features-section-v2 {
    margin-bottom: 150px;
    padding: 0 !important;
}
.features-section-v2 .features-text {
  padding-left: 60px;
}
.features-section-v2 .features-text h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: 0 0 10px 0;
}
.features-section-v2 .features-text p{
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    line-height: 40px;
    margin: 0 0 30px 0;
}
.features-section-v2 .features-listing {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}
.features-section-v2 .features-listing  li {
    width: 18.777%;
    height: 200px;
    margin: 0.6%;
    border: 1px solid #C4C4C4;
    border-radius: 20px;
    padding: 26px 16px;
}
.features-section-v2 .features-listing  li img {
    margin-bottom: 20px;
    height: 50px;
}
.features-section-v2 .features-listing  li h6 {
   font-size: var(--h6-font-size);
   font-weight: var(--h6-font-weight);
   color: var(--text-color);
   line-height: 20px;
   margin: 0;
}
.wemoke-location .location-text {
    padding-left: 60px;
}
.wemoke-location .location-text h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: 0 0 10px 0;
}
.wemoke-location .location-text p {
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    line-height: 40px;
    margin: 0 0 30px 0;
}
.wemoke-location img {
    width: 100%;
    height: 100%;
}
.wemoke-location  .cities-listing {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 0 150px 0;
}
.wemoke-location  .cities-listing li {
    width: 31.333%;
    margin: 1.5% 1%;
    position: relative;
}
.wemoke-location  .cities-listing li .img-box{
   position: relative;
   padding-top: 65%;
   width: 100%;
}
.wemoke-location  .cities-listing li .img-box img{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
 }
.wemoke-location  .cities-listing li p {
    font-size: var(--p-font-size);
    font-family: var(--common-font);
    font-weight: var(--p-font-weight);
    color: var(--text-color);
    margin: 15px 0 0 15px;
}

.wemoke-location  .special-event-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 22px 0 0 0;
    padding: 0 100px;
}
.wemoke-location {
  margin: 0 0 90px 0;
}
.wemoke-location  .special-event-wrap  h4 {
    font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: 0;
}
.wemoke-location  .special-event-wrap  h6 {
    font-size: var(--h6-font-size);
    font-weight: var(--h6-font-weight);
    color: var(--text-color);
    line-height: 20px;
    margin: 0;  
    max-width: 450px;
}
.wemoke-location  .special-event-wrap  .special-event-btn a {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    padding: var(--blueBtn-spacing);
    color: var(--blueBtn-color);
    text-transform: uppercase;
    background: var(--blueBtn);
    border: 1px solid var(--blueBtn);
    border-radius: 5px;
    font-family: var(--common-font);
    text-decoration: none;
}
.footer-images img {
    width: 100%;
    height: 100%;
}
.footer-images {
    margin-bottom: 110px;
}

header .header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
}
header .wemoke-logo {
    height: 84px;
}
header .nav-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}
header .nav-links  li a {
    font-family: var(--common-font);
    font-size: 18px;
    line-height: 19px;
    color: #000000;
    font-weight: 400 !important;
    padding: 0 0 0 48px;
    text-decoration: none;
    letter-spacing: 0.5px;
}
/* / ...............temstimonials-v2.............. / */
.version-two  .testimonials-bg {
  background: #F9F9F9 !important;
     padding: 63px 50px 50px 50px !important;
}
.version-two  .testimonials-bg p {
  color: var(--text-color) !important;
  margin: 0 auto 40px !important;
  text-align: center;
  line-height: 20px !important;
}
.version-two  .testimonials-bg h4 {
  color: var(--text-color) !important;
}
.version-two .testimonials-bg .slick-slider .slick-next::after {
  background-image: url(../src/images/version2/right-arrow.svg) !important;
  width: 40px !important;
  height: 40px !important;
  background-repeat: no-repeat;
  left: 15px;
}
.version-two .testimonials-bg .slick-slider .slick-prev::after {
  background-image: url(../src/images/version2/left-arrow.svg) !important;
  width: 40px !important;
  height: 40px !important;
  background-repeat: no-repeat;
  right: 15px;
}
.version-two  .testimonials-bg .slick-dots li button::before {
  color: #D9D9D9 !important ;
}
.version-two .testimonials-bg .slick-dots li.slick-active button::before {
  color: #005FDD !important;
    opacity: 1;
}
.version-two {
  background: #F9F9F9 !important;
  margin-bottom: 100px !important;
  padding: 69px 0;
}
.version-two  .testimonials-bg h5 {
   text-align: center !important;
   margin: 0 auto 60px !important;
}
.version-two .testimonials-bg h4 {
  text-align: center !important;
  margin: 0 auto !important;
}
.version-two  .testimonials-heading {
  font-size: var(--h4-font-size);
  font-family: var(--common-font);
  font-weight: var(--h4-font-weight);
  color: var(--text-color);
  margin: 0 ;
  text-align: center;
}


/* / ................header........................... / */
.headerv2 {
  display: flex;
    align-items: center;
    padding: 12px 0;
    justify-content: space-between;
    border-bottom: none !important;
}
.headerv2 .header-links  {
  display: flex;
  align-items: center;
}
.headerv2 .header-links li a {
  opacity: inherit !important;
}
.footerv2 h4::after {
    content: none !important;
}
.footerv2 a {
  font-weight: 600!important;
}

/* / ...............................hone-end.............. / */

/* / ...................history-css............ / */
.version-two-history .hero-img-wrap .overlay {
  background: none !important;
}
.version-two-history .history-heading {
  font-size: var(--h4-font-size);
  font-family: var(--common-font);
  font-weight: var(--h4-font-weight);
  color: var(--text-color);
  margin: var(--h4-margin);
  text-align: center;
}
.then-bottom-space {
  padding-top: 50% !important;
  width: 45% !important;
}
.right--para {
  width: 55% !important;
}
.version-two-history  .hero-img-wrap {
  padding-top: 50% !important;
  position: relative !important;
}
.version-two-history .hero-img-wrap img{
 width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 object-fit: cover;
}
.version-two-history .history-image {
  margin-bottom: 100px;
}
.version-two-history .mock-what h4 {
  font-size: var(--h4-font-size);
    font-family: var(--common-font);
    font-weight: var(--h4-font-weight);
    color: var(--text-color);
    margin: var(--h4-margin);
}
.version-two-history .mock-what p {
  font-size: var(--p-font-size) !important;
  font-family: var(--common-font) !important;
  font-weight: var(--p-font-weight) !important;
  color: var(--text-color) !important;
  line-height: 40px !important;
}
.version-two-history  .right-sec h4 {
  font-size: var(--h4-font-size) !important;
  font-family: var(--common-font) !important;
  font-weight: var(--h4-font-weight) !important;
  color: var(--text-color) !important;
  margin: var(--h4-margin) !important;
}
.version-two-history  .then-now-listing li {
  margin-bottom: 123px !important;
}
/* / .....................history-css-end............ / */
.contact--wrapper p {
  margin-bottom: 35px !important;
  font-size: 20px;
  max-width: 550px !important;
  width: 100% !important;
}
.contact--wrapper h2 {
  margin-bottom: 25px !important;
  font-weight: 700;
}
.version-two-history .then-para{
  font-size: 20px !important;
  line-height: 32px !important;
}
.moke--text {
  padding-left: 80px;
}
/* ................partner2..................... */
.header-wrapper {
  margin-bottom: 150px;
  margin-top: 32px;
}
.header-wrapper  h2{
  font-family: Graphik;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}
.header-wrapper  p {
  font-family: Neutrif Pro;
font-weight: 400;
font-size: 20px;
line-height: 42px;
text-align: center;
color: #000000;
text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}
.header-img {
  position: relative;
  padding-top: 60%;
  width: 100%;
}
.header-img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 00;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Benefits-wrapper {
  margin-bottom: 150px;
}
.Benefits-wrapper h2 {
  font-family: Graphik;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 28px;
}
.Benefits-wrapper p {
  font-family: Graphik;
font-weight: 400;
font-size: 20px;
line-height: 42px;
color: #000000;
padding-right: 80px;
}
.benifit-img {
  width: 100%;
}
.benifit-img img {
  width: 100%;
}
.Added-amenities {
  margin-bottom: 150px;
}
.amenities-text {
  padding-left: 90px;
}
.amenities-img {
 width: 100%;
}
.amenities-img img {
  width: 100%;
}
.Added-amenities h2 {
  font-family: Graphik;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 28px;
}
.Added-amenities p {
  font-family: Graphik;
font-weight: 400;
font-size: 20px;
line-height: 42px;
color: #000000;
}
.partner-points-wrapper{
  margin-bottom: 150px;
}
.partner-points-wrapper  ul {
list-style: disc;
padding: 0 5rem ;
}
.partner-points-wrapper  li {
  font-family: Graphik;
font-weight: 400;
font-size: 20px;
line-height: 32px;
color: #000000;
list-style-position: outside;
margin-bottom: 25px;
}
.partner-points-wrapper .contact-form {
  background: #F2F7FF;
  border-radius: 30px;
  padding: 80px 40px 40px 40px;
  margin: 100px 0 90px 0;
  text-align: center;
}
.partner-points-wrapper .form-listing {
  list-style: none;
    margin: 0;
    padding: 0;
}
.partner-points-wrapper .form-listing  li {
  width: 647px;
    max-width: 100%;
    border-radius: 7px;
    font-family: "Graphik";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #919191;
    margin: 0 auto 30px;
    position: relative;
}
.partner-points-wrapper .form-listing  li  input {
  width: 100%;
    height: 50px;
    border: 0;
    padding: 0 18px;
    border-radius: 7px;
    font-family: "Graphik";
    font-weight: 500;
    font-size: 16px;
    color: #919191;
}
.partner-points-wrapper .form-listing .flex-inp {
  display: flex;
  align-items: center;
}
.partner-points-wrapper .form-listing .flex-inp span {
  width: 50px;
  height: 50px;
  border: 0;
  padding: 0 14px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #005FDD;
}
.partner-points-wrapper .form-listing  li textarea {
  width: 100%;
    height: 150px;
    border: 0;
    padding: 18px;
    resize: none;
    border-radius: 7px;
    font-family: "Graphik";
    font-weight: 500;
    font-size: 16px;
    color: #919191;
}
.partner-points-wrapper .form-listing  li button {
  min-width: 100%;
  width: 100%;
  margin: 0;
}


/* / ...........................media-v2.................. / */
@media screen and (max-width:767px) {
  .sunshine-ride-wrapper .sunshine-img-wrap {
    margin-bottom: 70px;
  }
  .sunshine-ride-wrapper .sunshine-text h3 {
    font-size: 26px;
    line-height: 34px;   font-size: 26px;
    line-height: 34px;
  }
  .sunshine-ride-wrapper .sunshine-text p {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .moke-section .moke-text h4 {
    font-size: 26px;
    line-height: 34px;
  }
  .moke-section .moke-text p {
    font-size: 16px;
    line-height: 34px;
    padding-left: 0;
  }
  .moke-section .moke-text {
    margin-bottom: 40px;
    padding-left: 0;
    text-align: center;
  }
  .moke-section {
    margin-bottom: 70px;
  }
  .wemoke-exper-wrap .Exper-text {
    padding: 0 0 0 60px;
    padding-left: 0;
  }
  .wemoke-exper-wrap .Exper-text h4{
    font-size: 26px;
    line-height: 34px;
    text-align: center;
  }
  .moke-rolls .moke-roll-wrap .moke-roll-image {
    width: 100%;
    padding-top: 100%;
  }
  .moke-rolls .moke-roll-text {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 0;
    text-align: center;
  }
  .wemoke-exper-wrap {
    margin-bottom: 70px;
  }
  .features-section-v2 .features-text {
    text-align: center;
    padding: 0;
  }
  .moke-rolls {
    margin-bottom: 70px;
  }
  .moke-rolls .moke-roll-text h4{
    font-size: 26px;
    line-height: 34px ;
  }
  .features-section-v2 .features-text h4 {
    font-size: 26px;
    line-height: 34px;
  }
  .wemoke-exper-wrap .Exper-text p {
    font-size: 16px;
    line-height: 34px;
    text-align: center;
  }
  .moke-rolls .moke-roll-text p {
    font-size: 16px;
    line-height: 34px;
  }
  .features-section-v2 .features-text p {
    font-size: 16px;
    line-height: 34px;
  }
  .features-section-v2 .features-listing li {
    width: 100%;
    margin: 2% 0;
    text-align: center;
  }
  .features-section-v2 .features-listing li img {
    height: 72px;
  }
  .features-section-v2 {
    margin-bottom: 70px;
  }
  .wemoke-location .location-text h4 {
    font-size: 26px;
    line-height: 34px;
  }
  .wemoke-location .cities-listing li {
    width: 100%;
    margin: 3% 0;
  }
  .wemoke-location .cities-listing {
    margin-bottom: 70px;
  }
  .wemoke-location .special-event-wrap {
    flex-wrap: wrap;
    padding: 0;
    text-align: center;
  }
  .wemoke-location {
    margin-bottom: 70px;
  }
  .wemoke-location .special-event-wrap h4 {
    font-size: 26px;
    line-height: 34px;
  }
  .wemoke-exper-wrap .exper-img-space {
    margin-bottom: 20px;

  }
  .wemoke-location  .cities-space {
    margin-bottom: 20px;
  }
  .wemoke-location .special-event-wrap h6 {
    margin: 0 0 30px 0;
  }
  .footer-images .footer-img-spacing {
    margin-bottom: 20px;
  }
  .moke--text {
    padding-left: 0;
    text-align: center;
  }
  .version-two-history .history-image {
    margin-bottom: 60px;
  }
  .wemoke-location .location-text {
    padding-left: 0;
    text-align: center;
  }
  .wemoke-location .cities-listing li p {
    text-align: center;
  }
  .special-event-btn {
    width: 100%;
    text-align: center;
  }
  .then-now-listing li .right-sec {
    text-align: center;
  }


}
@media screen and (max-width:992px) {
  .then-bottom-space {
    padding-top: 100% !important;
    width: 100% !important;
  }
  .right--para {
    width: 100% !important;
  }
  .then-bottom-space {
    margin-bottom: 30px;
  }
  .Benefits-wrapper p {
    padding: 0;
  }
  .amenities-text {
    padding: 0;
  }
  .partner-points-wrapper ul {
    padding: 0 0 0 40px;
  }
  .header-wrapper {
    margin-bottom: 100px;
  }
  .Added-amenities {
    margin-bottom: 100px;
  }
  .amenities-img {
    margin-bottom: 50px;
  }
  .partner-points-wrapper .contact-form {
    margin: 80px 0;
    padding: 50px 15px;
  }
}
@media screen and (min-width:768px) and (max-width:992px) {
  .wemoke-location .special-event-wrap {
    padding: 0;
  }
  .features-section-v2 .features-listing li {
    width: 48%;
    margin: 1%;
  }
  .moke-section .moke-text {
    margin-bottom: 40px;
  }
  .moke-rolls .moke-roll-text {
    max-width: 100%;
    width: 100%;
  }
  .moke-rolls .moke-roll-wrap .moke-roll-image {
    width: 100%;
  }
}



.header-section .logoImg {
  width: 98px;
  margin: 15px auto 50px;
  display: block;
}
.header-section .header-heading {
  font-family: 'Graphik';
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #000000;
}

.formbg {
  width: 1068px;
  max-width: 90%;
  background: rgba(114, 187, 255, 0.5);
  border-radius: 30px;
  padding: 40px;
  margin: 60px auto 60px;
}
.review-name {
  border: 1px solid #fff;
  padding: 5px 12px;
  display: block;
  margin: 0 auto 20px;
  font-size: 14px;
  max-width: 300px;
  width: 100%;
}
.review-submit-btn {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
 
}
.review-submit-btn  button {
  background: #005fdd;
  color: #fff;
  border: 1px solid #005fdd;
  padding: 6px;
  max-width: 100%;
  border-radius:6px; 
  width: 100%;
}
.review-message {
  color: #2c8b14;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 0 0;
  text-align: center;
}


