@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Black.eot');
    src: url('Raleway-Black.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Black.woff2') format('woff2'),
        url('Raleway-Black.woff') format('woff'),
        url('Raleway-Black.ttf') format('truetype'),
        url('Raleway-Black.svg#Raleway-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBold.eot');
    src: url('Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-SemiBold.woff2') format('woff2'),
        url('Raleway-SemiBold.woff') format('woff'),
        url('Raleway-SemiBold.ttf') format('truetype'),
        url('Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Bold.eot');
    src: url('Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Bold.woff2') format('woff2'),
        url('Raleway-Bold.woff') format('woff'),
        url('Raleway-Bold.ttf') format('truetype'),
        url('Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBold.eot');
    src: url('Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ExtraBold.woff2') format('woff2'),
        url('Raleway-ExtraBold.woff') format('woff'),
        url('Raleway-ExtraBold.ttf') format('truetype'),
        url('Raleway-ExtraBold.svg#Raleway-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Medium.eot');
    src: url('Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Medium.woff2') format('woff2'),
        url('Raleway-Medium.woff') format('woff'),
        url('Raleway-Medium.ttf') format('truetype'),
        url('Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Regular.eot');
    src: url('Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Regular.woff2') format('woff2'),
        url('Raleway-Regular.woff') format('woff'),
        url('Raleway-Regular.ttf') format('truetype'),
        url('Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

