.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.text-block-second ul li::after,
.text-block-second ul li:before {
    background-color: rgb(0, 0, 0) !important;
    width: 50px !important;
    height: 50px !important;
    
}
.text-block-second  h3 {
    text-decoration: underline;
} 
.text-block-second  h4 {
    text-decoration: underline;
} 
.text-block-second ul {
  margin: 10px 0px 10px 40px;
    list-style: disc;
}
.text-block-second  .information-we-collec {
    margin: 10px 0;
}
.text-block-second  .insert-as {
    background-color: yellow;
    color: black;
    text-decoration: none;
}
.text-block-second  .insert-as:hover {
   color: black;
}
.text-block-second  .your-california {
    color: black;
    text-decoration: underline;
  
}
.text-block-second {
  padding: 30px 0;
}
.text-font {
    font-family: 'Raleway';
}



.text-block-second .bg-color  {
  background-color: #ffff00;
  color: #000;
}
.terms_condition {
   font-family: 'Graphik';
}
.text-block-second .privacy-policy {
  color: #000;
  text-decoration: underline;
}


.arrow-icon {
  width: 15px;
}

.faq_sub_heading .faq-title {
  justify-content: center !important;
  display: flex;
  padding: 20px 0 !important;
}
.faq_sub_heading .faq-body .row-title  {
  font-weight: 500!important;
}