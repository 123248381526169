.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.219);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #6f42c1;
  /* background: black; */
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* car loader  */

.loader {
  height: 100vh;
  display: inline;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255);
  z-index: 9999999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.middleContainer {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.car__body {
  animation: shake 0.2s ease-in-out infinite alternate;
}


.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  animation: line 0.8s ease-in-out infinite;
  animation-fill-mode: both;
}


.car__line--top {
  animation-delay: 0s;
}

.car__line--middle {
  animation-delay: 0.2s;
}

.car__line--bottom {
  animation-delay: 0.4s;
}

.car {}


@keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}